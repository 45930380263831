<template>
  <div>
    <el-backtop :bottom="60"></el-backtop>
    <el-page-header @back="dynamic" content="详细信息" style="margin-bottom: 15px;">
    </el-page-header>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-descriptions v-if="$route.query.type == 1" :title="news.newsTitle">
          <el-descriptions-item label="作者">{{ news.userId }}</el-descriptions-item>
          <el-descriptions-item label="发布时间">{{ news.createTime }}</el-descriptions-item>
          <el-descriptions-item label="更新时间">{{ news.updateTime }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions v-if="$route.query.type == 2" :title="news.noticeTitle">
          <el-descriptions-item label="作者">{{ news.userId }}</el-descriptions-item>
          <el-descriptions-item label="更新时间">{{ news.upDate }}</el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="text item ql-container" style="min-height: 300px">
        <div class="ql-editor" v-if="$route.query.type == 1" v-html="news.news"></div>
        <div class="ql-editor" v-if="$route.query.type == 2" v-html="news.notice"></div>
      </div>
    </el-card>
    <el-card style="margin-top: 10px">
      <el-avatar shape="square" :size="40">匿名</el-avatar>
    </el-card>

  </div>

</template>

<script>
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
export default {
  name: "reader",
  data() {
    return {
      news: ''
    }
  },
  mounted() {
    this.getNewsById(this.$route.query.id, this.$route.query.type)
  },
  methods: {
    dynamic() {
      if (this.$route.query.type == 1) {
        this.$router.push("../release")
      }
      if (this.$route.query.type == 2) {
        this.$router.push("../notice")
      }

    },
    getNewsById(newsId, type) {
      // eslint-disable-next-line no-unused-vars
      var newsUri = ''
      if (type == 1) {
        newsUri = '/api/news/getNewsById/'
      }
      if (type == 2) {
        newsUri = '/api/notice/getNoticeById/'
      }
      console.log(newsUri )
      this.$http({
        method: "GET",
        url: newsUri + newsId,
        data: ""
      }).then((res)=>{
        if (res.data.status) {
          this.news = res.data.data
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.w-50 {
  width: 50%;
  margin-left: 18%;
}
</style>